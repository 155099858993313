import React, { useEffect, useState } from "react";
import OneAnswer from "../../AnswerPages/OneAnswer";
import MultiAnswer from "../../AnswerPages/MultiAnswer";
import { useSwipeable } from "react-swipeable";
import { useSearchParams } from "react-router-dom";

export default function Quiz({
  askNum,
  setAskNum,
  outputAnswers,
  setOutputAnswers,
  setUpdate,
  isFrame,
  quizData,
}) {
  const [, setSearchParams] = useSearchParams({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSearchParams({}), []);

  function goToNext() {
    if (
      askNum < quizData.length - 1 ||
      outputAnswers.length > quizData.length - 1
    ) {
      setAskNum(askNum + 1);
      setIsTimeOut(0);
    }
  }

  function goToAsk(askNum) {
    if (askNum < outputAnswers.length + 1) {
      setAskNum(askNum);
      setIsTimeOut(0);
    }
  }

  function goToPrev() {
    if (askNum > 0) {
      setAskNum(askNum - 1);
      setIsTimeOut(0);
    }
  }

  function rememberAnswersOne(answer) {
    let memo = outputAnswers;
    if (
      outputAnswers.length === 0 ||
      outputAnswers.find((e) => e.ask === askNum) === undefined
    ) {
      setOutputAnswers([...outputAnswers, { ask: askNum, answers: [answer] }]);
    } else {
      let i = outputAnswers.findIndex((e) => e.ask === askNum);
      if (memo[i].answers.length !== 0) {
        if (memo[i].answers[0] === answer) {
          memo[i].answers.pop();
          setOutputAnswers(memo);
        } else {
          memo[i].answers = [answer];
          setOutputAnswers(memo);
        }
      } else {
        memo[i].answers = [answer];
        setOutputAnswers(memo);
      }
    }
    setUpdate(Date.now());
  }

  function rememberAnswersMulti(answer) {
    let memo = outputAnswers;
    // проверка, если массив ответов пуст или в нём нет вопроса с нужным номером
    if (
      outputAnswers.length === 0 ||
      outputAnswers.find((e) => e.ask === askNum) === undefined
    ) {
      setOutputAnswers([...outputAnswers, { ask: askNum, answers: [answer] }]);
    } else {
      // поиск индекса вопроса в массиве
      let i = outputAnswers.findIndex((e) => e.ask === askNum);
      if (answer === 0) {
        if (memo[i].answers.includes(0)) {
          memo[i].answers = [];
          setOutputAnswers(memo);
        } else {
          memo[i].answers = [0];
          setOutputAnswers(memo);
        }
      } else {
        if (memo[i].answers.includes(0)) {
          memo[i].answers = [];
        }
        if (memo[i].answers.length !== 0) {
          if (memo[i].answers.indexOf(answer) >= 0) {
            memo[i].answers.splice(memo[i].answers.indexOf(answer), 1);
            setOutputAnswers(memo);
          } else {
            memo[i].answers.push(answer);
            setOutputAnswers(memo);
          }
        } else {
          memo[i].answers.push(answer);
          setOutputAnswers(memo);
        }
      }
    }
    setUpdate(Date.now());
  }

  const [isTimeOut, setIsTimeOut] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setIsTimeOut(1);
    }, 200);
  }, [askNum]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isAnswered()) {
        goToNext();
      }
    },
    onSwipedRight: () => {
      goToPrev();
    },
  });

  function searchAnswer(answerNum) {
    for (const item of outputAnswers) {
      if (item.ask === askNum) {
        for (const answer of item.answers)
          if (answer === answerNum) {
            return true;
          }
      }
    }
    return false;
  }

  function isAnswered() {
    for (const item of outputAnswers) {
      if (item.ask === askNum) {
        if (item.answers.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <div className="quiz" {...handlers}>
      {quizData.length > askNum ? (
        quizData[askNum].isMulti ? (
          <MultiAnswer
            answerZero={quizData[askNum].answerZero}
            rememberAnswers={rememberAnswersMulti}
            coverJPG={quizData[askNum].cover.jpg}
            coverAVIF={quizData[askNum].cover.avif}
            coverWEBP={quizData[askNum].cover.webp}
            ask={quizData[askNum].ask}
            title={quizData[askNum].title}
            answers={quizData[askNum].answer}
            goToNext={goToNext}
            goToPrev={goToPrev}
            askNum={askNum}
            outputAnswers={outputAnswers}
            isTimeOut={isTimeOut}
            goToAsk={goToAsk}
            searchAnswer={searchAnswer}
            isAnswered={isAnswered}
            length={quizData.length}
          />
        ) : (
          <OneAnswer
            length={quizData.length}
            rememberAnswers={rememberAnswersOne}
            coverJPG={quizData[askNum].cover.jpg}
            coverAVIF={quizData[askNum].cover.avif}
            coverWEBP={quizData[askNum].cover.webp}
            ask={quizData[askNum].ask}
            title={quizData[askNum].title}
            answers={quizData[askNum].answer}
            goToNext={goToNext}
            goToPrev={goToPrev}
            askNum={askNum}
            outputAnswers={outputAnswers}
            isTimeOut={isTimeOut}
            setIsTimeOut={setIsTimeOut}
            goToAsk={goToAsk}
            searchAnswer={searchAnswer}
            isAnswered={isAnswered}
            isFrame={isFrame}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
}
