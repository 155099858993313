import React from "react";
import ButtonModel from "../../../UI/ButtonModel/ButtonModel";
import Form from "../../../UI/Form/Form";
import { useLocation } from "react-router-dom";
import SendStatus from "../../../UI/SendStatus/SendStatus";

export default function ResultModel({
  city,
  dealer,
  setDealer,
  result,
  numOfModel,
  setIsSend,
  isSend,
  isSuccesSend,
  postForm,
  isInProcess,
  setIsInProcess,
  setIsSuccesSend,
  form,
  buttons,
}) {
  const location = useLocation();
  async function postAndScroll(formData) {
    const node = document.getElementsByClassName("interactive")[0];
    await postForm(formData).finally(node.scrollIntoView({ behavior: "smooth"}))
  }

  return (
    <div className="content">
      <div className="title-cover">
        <div className="title">
          <h1>{result[numOfModel].title}</h1>
        </div>
        <div className="cover">
          <picture>
            <source srcSet={result[numOfModel].cover.avif} type="image/avif" />
            <source srcSet={result[numOfModel].cover.webp} type="image/webp" />
            <source srcSet={result[numOfModel].cover.jpg} />
            <img
              src={result[numOfModel].cover.jpg}
              alt="Изображение автомобиля"
            />
          </picture>
        </div>
        <div className="model-info">
          <div className="text">
            <div className="horiz-ln"></div>
            <p>{result[numOfModel].text}</p>
          </div>
          {buttons ? (
            <div className="btns">
              <ButtonModel linkTo={result[numOfModel].link}>
                <p>Узнать больше</p>
              </ButtonModel>
              <ButtonModel linkTo={location.pathname}>
                {" "}
                <p>Пройти заново</p>{" "}
              </ButtonModel>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {form.isEnable ? (
        <div className="interactive">
          {isSend ? (
            <SendStatus isSuccesSend={isSuccesSend} setIsSend={setIsSend} />
          ) : (
            <>
              <h1>{form.title}</h1>
              <Form
                city={city}
                dealer={dealer}
                setDealer={setDealer}
                postForm={postAndScroll}
                setIsSend={setIsSend}
                isInProcess={isInProcess}
                setIsInProcess={setIsInProcess}
                setIsSuccesSend={setIsSuccesSend}
              />
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
