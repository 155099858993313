import React from "react";

export default function Arrow({ direction }) {
  return (
    <>
      {direction === "left" ? (
        <svg
          style={{transform: "matrix(-1, 0, 0, 1, 0, 0)"}}
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12L19 12M19 12L12 5M19 12L12 19"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#FFFFFF"
          />
        </svg>
      ) : (
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12L19 12M19 12L12 5M19 12L12 19"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#FFFFFF"
          />
        </svg>
      )}
    </>
  );
}
