import React, { useEffect } from "react";
import "./AnswerPages.style.sass";
import NavBottom from "../../UI/NavBottom/NavBottom";
import ButtonAnswer from "../../UI/ButtonAnswer/ButtonAnswer";
import Indicators from "../../UI/Indicators/Indicators";

export default function OneAnswer({
  title,
  ask,
  answers,
  coverJPG,
  coverAVIF,
  coverWEBP,
  goToPrev,
  goToNext,
  askNum,
  rememberAnswers,
  outputAnswers,
  isTimeOut,
  goToAsk,
  searchAnswer,
  isAnswered,
  isFrame,
  length,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askNum]);

  return (
    <div className="answers-page">
      <div className="content">
        <div className="title-cover">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="cover">
            <picture>
              <source srcSet={coverAVIF} type="image/avif" />
              <source srcSet={coverWEBP} type="image/webp" />
              <source srcSet={coverJPG} />
              <img
                className={isFrame ? "min-height-img" : ""}
                src={coverJPG}
                alt="Изображение автомобиля"
              />
            </picture>
          </div>
        </div>
        <div className="interactive">
          <Indicators totalCount={length} curEl={askNum + 1} goToAsk={goToAsk} />
          <div className="ask">
            <h2>{ask}</h2>
          </div>
          <div className="answers">
            {answers.map((item, index) => (
              <ButtonAnswer
                isActive={true}
                isChosen={searchAnswer(index)}
                children={item}
                key={index}
                onClick={() => {
                  rememberAnswers(index);
                  goToNext();
                }}
                isTimeOut={isTimeOut}
              />
            ))}
          </div>
          <NavBottom
            goToNext={goToNext}
            goToPrev={goToPrev}
            outputAnswers={outputAnswers}
            isStart={askNum === 0 ? true : false}
            isEnd={askNum === length - 1 ? true : false}
            askNum={askNum}
            isAnswered={isAnswered()}
          />
        </div>
      </div>
    </div>
  );
}
