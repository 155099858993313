import React from "react";
import "./SendStatus.style.sass";
import ButtonModel from "../ButtonModel/ButtonModel";
import Arrow from "../Arrow/Arrow";

export default function SendStatus({ isSuccesSend, setIsSend }) {
  return (
    <div className="send-status">
      {isSuccesSend ? (
        <>
          <h2>Спасибо! Форма успешно отправлена</h2>
          <p>Дождитесь, пожалуйста, звонка от выбранного дилерского ценра.</p>
          <p>Эту страницу можно покинуть</p>
        </>
      ) : (
        <>
          <h2>Упс! Что-то пошло не так...</h2>
          <p>
            Приносим извинения за технические неполадки. Попробуйте вернуться и
            повторить отправку.
          </p>
          <ButtonModel
            onClick={() => {
              setIsSend(false);
            }}
          >
            Вернуться к форме
            <Arrow />
          </ButtonModel>
        </>
      )}
    </div>
  );
}
