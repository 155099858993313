import React from "react";

export default function IndDisable({ onClick }) {
  return (
    <div style={{cursor: "pointer"}} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="2"
        viewBox="0 0 26 2"
        fill="none"
      >
        <path
          d="M1 1H25"
          stroke="white"
          strokeOpacity="0.25"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}
