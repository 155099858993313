import React from "react";
import "./ButtonAnswer.style.sass";

export default function ButtonAnswer({
  children,
  isActive,
  onClick,
  isChosen,
  isTimeOut
}) {
  return (
    <button
      style={
        isTimeOut === 0
          ? {}
          : isTimeOut === 1
          ? {
              transition:
                "opacity 0.2s ease-in-out, transform 0.2s ease-in-out",
              opacity: "1",
              transform: "translateX(0)",
            }
          : { opacity: "1", transform: "translateX(0)" }
      }
      className={
        isChosen
          ? "btn-answer chosen"
          : isActive
          ? "btn-answer active"
          : "btn-answer disabled"
      }
      onClick={() => {
        onClick();
      }}
    >
      <p>{children}</p>
      {isChosen ? (
        <svg
          strokeWidth="2px"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 6L9 17L4 12"
            strokeOpacity="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#FFFFFF"
          />
        </svg>
      ) : (
        <></>
      )}
    </button>
  );
}
