import React, { useEffect, useState } from "react";
import IndDisable from "./IndDisable/IndDisable";
import IndActive from "./IndActive/IndActive";
import "./Indicators.sass";

export default function Indicators({ totalCount, curEl, goToAsk }) {
  const [arrOfInd, setArrOfInd] = useState([]);
  function createArr() {
    let arr = [];
    for (let i = 0; i < totalCount; i++) {
      if (i !== curEl - 1) {
        arr.push(0);
      } else {
        arr.push(1);
      }
    }
    setArrOfInd(arr);
  }

  useEffect(() => {
    createArr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEl]);
  return (
    <div className="indicators">
      {arrOfInd.map((e, index) =>
        e === 0 ? (
          <IndDisable
            key={index}
            onClick={() => {
              goToAsk(index);
            }}
          />
        ) : (
          <IndActive key={index} />
        )
      )}
    </div>
  );
}
