import React from "react";

export default function IndActive() {
  return (
    <div>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="4"
      viewBox="0 0 34 4"
      fill="none"
    >
      <rect width="30" height="0" transform="translate(2 2)" fill="white" />
      <path
        d="M2 2H32"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
    </div>
    
  );
}
