import React, { useEffect } from "react";
import ButtonAnswer from "../../UI/ButtonAnswer/ButtonAnswer";
import NavBottom from "../../UI/NavBottom/NavBottom";
import "./AnswerPages.style.sass";
import Indicators from "../../UI/Indicators/Indicators";

export default function MultiAnswer({
  title,
  ask,
  answers,
  coverJPG,
  coverAVIF,
  coverWEBP,
  goToPrev,
  goToNext,
  answerZero,
  askNum,
  rememberAnswers,
  outputAnswers,
  isTimeOut,
  goToAsk,
  searchAnswer,
  isAnswered,
  length,
}) {
  function checkActive(index) {
    if (
      outputAnswers.findIndex(
        (e) => e.ask === askNum && e.answers.length !== 0
      ) < 0
    ) {
      if (index > 0) return true;
      else return false;
    } else {
      for (const item of outputAnswers) {
        if (item.ask === askNum) {
          for (const answer of item.answers)
            if (answer !== 0) {
              return true;
            }
        }
      }
      return false;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askNum]);

  return (
    <div className="answers-page">
      <div className="content">
        <div className="title-cover">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="cover">
            <picture>
              <source srcSet={coverAVIF} type="image/avif" />
              <source srcSet={coverWEBP} type="image/webp" />
              <source srcSet={coverJPG} />
              <img src={coverJPG} alt="Изображение автомобиля" />
            </picture>
          </div>
        </div>
        <div className="interactive">
          <Indicators totalCount={length} curEl={askNum + 1} goToAsk={goToAsk} />
          <div className="ask">
            <h2>{ask}</h2>
            <p>* Вы можете выбрать несколько выриантов ответа</p>
          </div>
          <div className="answers">
            {answers.map((item, index) => (
              <ButtonAnswer
                isActive={checkActive(index + 1)}
                isChosen={searchAnswer(index + 1)}
                children={item}
                key={index + 1}
                onClick={() => {
                  rememberAnswers(index + 1);
                }}
                isTimeOut={isTimeOut}
              />
            ))}
            {answerZero ? (
              <div className="answer-none">
                <ButtonAnswer
                  isActive={!checkActive(0)}
                  isChosen={searchAnswer(0)}
                  children={answerZero}
                  key={0}
                  onClick={() => {
                    rememberAnswers(0);
                  }}
                  isTimeOut={isTimeOut}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <NavBottom
            goToNext={goToNext}
            goToPrev={goToPrev}
            outputAnswers={outputAnswers}
            isStart={askNum === 0 ? true : false}
            isEnd={askNum === length - 1 ? true : false}
            isAnswered={isAnswered()}
          />
        </div>
      </div>
    </div>
  );
}
