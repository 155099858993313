import axios from "axios";

export async function APIgetCityDealer() {
  const options = {
    method: "GET",
    url: "https://exeed.ru/data/dealer-centers-by-city-test-drive/index.json",
  };
  let responseOutput = {};
  await axios
    .request(options)
    .then((response) => (responseOutput = response))
    .catch(function (error) {
      console.log(error);
      throw new Error(error);
    });
  return responseOutput;
}

export async function APIpostFromData(path, data) {
  const options = {
    method: "POST",
    url: path,
    data: data,
  };
  let responseOutput = {};

  await axios
    .request(options)
    .then(function (response) {
      //console.log(response);
      responseOutput = response;
    })
    .catch(function (error) {
      console.log(error);
      throw new Error(error);
    });

  return responseOutput;
}
