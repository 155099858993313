import React, { useCallback, useState } from "react";
import "./ButtonModel.style.sass";

export default function ButtonModel({ linkTo, onClick, children }) {
  const [viewBox, setViewBox] = useState(null);
  const [d, setD] = useState("");
  const [strokeDasharray, setStrokeDasharray] = useState(null);
  const [strokeDashoffset, setStrokeDashoffset] = useState(null);
  const [strokeDash, setStrokeDash] = useState(null);
  const [strokeDashHover, setStrokeDashHover] = useState(null);
  const div = useCallback((node) => {
    if (node !== null) {
      document.fonts.ready.then(() => {
        const width = node.getBoundingClientRect().width;
        setViewBox("0 0 " + width + " 59");
        setD(
          "M1 11 L11 1 H" +
            (width - 11) +
            " L" +
            (width - 1) +
            " 11 L" +
            (width - 1) +
            " 48 L" +
            (width - 11) +
            " 58 L11 58 L1 48 V11 L11 1"
        );
        const sd = (width + 59) * 2;
        setStrokeDasharray(sd + ", " + sd);
        setStrokeDashoffset(sd + (width + 59) + 14);
        setStrokeDash(sd + (width + 59) + 14);
        setStrokeDashHover(sd - (width + 59) + 3);
      });
    }
  }, []);

  return (
    <div className="btn-model">
      <a
        href={linkTo ? linkTo : ""}
        onClick={onClick}
        onMouseEnter={() => {
          setStrokeDashoffset(strokeDashHover);
        }}
        onMouseLeave={() => {
          setStrokeDashoffset(strokeDash);
        }}
      >
        <div ref={div} className="chld-model">
          {children}
        </div>
        <div className="border-model">
          <svg viewBox={viewBox}>
            <path
              d={d}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
            ></path>
          </svg>
        </div>
      </a>
    </div>
  );
}
