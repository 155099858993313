import React, { useEffect, useState } from "react";
import Result from "./Result/Result";
import Quiz from "./Quiz/Quiz";

export default function QuizResult({ setUpdate, isFrame, data }) {
  const [askNum, setAskNum] = useState(0);
  const [outputAnswers, setOutputAnswers] = useState([]);
  const quiz = data.quiz;

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
      });
    };

    quiz.map(async (ask) => {
      await loadImage(ask.cover.avif);
      return 0;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return quiz.length > askNum ? (
    <Quiz
      askNum={askNum}
      setAskNum={setAskNum}
      outputAnswers={outputAnswers}
      setOutputAnswers={setOutputAnswers}
      setUpdate={setUpdate}
      isFrame={isFrame}
      quizData={quiz}
    />
  ) : (
    <Result outputAnswers={outputAnswers} setUpdate={setUpdate} data={data} />
  );
}
