import React from "react";
import QuizResult from "../QuizResult/QuizResult";
import WelcomePage from "../WelcomePage/WelcomePage";
import Result from "../QuizResult/Result/Result";
import { useLocation } from "react-router-dom";
import appGallery from "../../../assets/icons/app-gallery.svg";
import appGpm from "../../../assets/icons/app-gpm.svg";
import appStore from "../../../assets/icons/app-store.svg";

export default function FullPage({ page, setUpdate, data }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const model = searchParams.get("model");

  function Logo({ href, ariaLabel }) {
    return (
      <a href={href} aria-label={ariaLabel}>
        <svg
          width="126"
          height="14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.372 14V0h17.636A2.991 2.991 0 01126 2.99v8.02c0 1.65-1.34 2.99-2.992 2.99h-17.636zm15.705-11.453h-12.275v8.906h12.275c.824 0 1.494-.668 1.494-1.494V4.04c0-.826-.67-1.494-1.494-1.494z"
            fill="#fff"
          />
          <path
            d="M2.967.02A2.967 2.967 0 000 2.986v7.956a2.966 2.966 0 002.967 2.964h17.494V11.38H4.885c-.82 0-1.483-.664-1.483-1.482V8.12h15.806V5.596H3.402V4.028c0-.82.663-1.481 1.483-1.481H20.46V.02H2.967zm33.628 5.209L30.03.149h-4.45l8.789 6.802-8.79 6.803h4.452l6.564-5.08 6.563 5.08h4.452L38.82 6.95l8.79-6.8h-4.452l-6.563 5.08zm16.134-2.232A2.966 2.966 0 0155.696.033h17.493v2.525H57.612c-.82 0-1.483.663-1.483 1.482v1.566h15.807v2.526H56.13v1.78c0 .816.664 1.48 1.483 1.48H73.19v2.527H55.697a2.966 2.966 0 01-2.967-2.964V2.997zM82.06.044a2.966 2.966 0 00-2.968 2.965v7.957a2.966 2.966 0 002.968 2.965h17.493v-2.525H83.977c-.82 0-1.484-.665-1.484-1.483V8.145h15.806V5.617H82.493V4.053c0-.82.664-1.483 1.484-1.483h15.576V.044H82.06z"
            fill="#fff"
          />
        </svg>
      </a>
    );
  }
  return data ? (
    <>
      <header>
        <div className="header">
          <Logo ariaLabel="Перейти на главную страницу опроса" href="/" />
        </div>
      </header>
      {page === "welcome" ? (
        <WelcomePage data={data} />
      ) : model ? (
        <Result setUpdate={setUpdate} modelParam={model} data={data} />
      ) : (
        <QuizResult setUpdate={setUpdate} data={data} />
      )}
      <footer>
        <div className="footer">
          <div className="footer-main">
            <div className="footer-logo">
              <Logo
                ariaLabel="Перейти на главную страницу Exeed"
                href="https://exeed.ru/"
              />
            </div>
            <div className="footer-app">
              <p>Мобильное приложение</p>
              <div className="app-link">
                <a
                  href="https://apps.apple.com/ru/app/exeed/id6449838425"
                  aria-label="Приложение EXEED в App Store"
                  rel="nofollow"
                >
                  <img src={appStore} alt="App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=ru.exeed.app"
                  aria-label="Приложение EXEED в Google Play"
                  rel="nofollow"
                >
                  <img src={appGpm} alt="Google Play" />
                </a>
                <a
                  href="https://appgallery.huawei.com/#/app/C108663463"
                  aria-label="Приложение EXEED в AppGallery"
                  rel="nofollow"
                >
                  <img src={appGallery} alt="AppGallery" />
                </a>
              </div>
            </div>
            <div className="footer-phone">
              <div className="phone">
                <p>Горячая линия</p>
                <a
                  aria-label="Телефон горячей линии"
                  href="tel:88007008003"
                  data-ym-event="click_phone"
                >
                  <div className="contact-phone">8 800 700-80-03</div>
                </a>
              </div>
              <div className="phone">
                <p>С мобильного</p>
                <a
                  aria-label="Телефон горячей линии с мобильного"
                  href="tel:*7009"
                  data-ym-event="click_phone"
                >
                  <div className="contact-phone">*7009</div>
                </a>
              </div>
            </div>
            <div className="footer-media">
              <p>EXEED в соцсетях</p>
              <div className="media">
                <a aria-label="EXEED в ВК" href="https://vk.com/exeedru">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.02944 7.02944C0 14.0589 0 25.3726 0 48V52C0 74.6274 0 85.9411 7.02944 92.9706C14.0589 100 25.3726 100 48 100H52C74.6274 100 85.9411 100 92.9706 92.9706C100 85.9411 100 74.6274 100 52V48C100 25.3726 100 14.0589 92.9706 7.02944C85.9411 0 74.6274 0 52 0H48C25.3726 0 14.0589 0 7.02944 7.02944ZM16.8752 30.4169C17.4168 56.4169 30.4167 72.0418 53.2084 72.0418H54.5003V57.1668C62.8753 58.0001 69.2082 64.1252 71.7498 72.0418H83.5835C80.3335 60.2085 71.7914 53.6668 66.4581 51.1668C71.7914 48.0835 79.2915 40.5835 81.0831 30.4169H70.3328C67.9995 38.6669 61.0836 46.1668 54.5003 46.8751V30.4169H43.7499V59.2501C37.0833 57.5835 28.6668 49.5002 28.2918 30.4169H16.8752Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  aria-label="EXEED в Telegram"
                  href="https://t.me/exeedrussia"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0,0,256,256"
                    width="24px"
                    height="24px"
                  >
                    <g
                      fill="#ffffff"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                    >
                      <g transform="scale(5.33333,5.33333)">
                        <path d="M5.83,23.616c12.568,-5.529 28.832,-12.27 31.077,-13.203c5.889,-2.442 7.696,-1.974 6.795,3.434c-0.647,3.887 -2.514,16.756 -4.002,24.766c-0.883,4.75 -2.864,5.313 -5.979,3.258c-1.498,-0.989 -9.059,-5.989 -10.7,-7.163c-1.498,-1.07 -3.564,-2.357 -0.973,-4.892c0.922,-0.903 6.966,-6.674 11.675,-11.166c0.617,-0.59 -0.158,-1.559 -0.87,-1.086c-6.347,4.209 -15.147,10.051 -16.267,10.812c-1.692,1.149 -3.317,1.676 -6.234,0.838c-2.204,-0.633 -4.357,-1.388 -5.195,-1.676c-3.227,-1.108 -2.461,-2.543 0.673,-3.922z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
                <a
                  aria-label="EXEED в YouTube"
                  href="https://www.youtube.com/channel/UCvcyIs3g-WpafDNQToiWxow"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    width="24px"
                    height="24px"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 71.412065 50"
                  >
                    <g id="g5" transform="scale(.58824)">
                      <path
                        id="path7"
                        fill="#fff"
                        fillOpacity="1"
                        d="M35.705078 0S13.35386.0001149 7.765625 1.4707031c-3 .8235294-5.4713925 3.2946921-6.2949219 6.3535157C.0001149 13.412454 0 25 0 25s.0001149 11.64637 1.4707031 17.175781c.8235294 3.058824 3.2360984 5.471393 6.2949219 6.294922C13.412684 50.000115 35.705078 50 35.705078 50s22.353171-.000115 27.941406-1.470703c3.058824-.82353 5.471393-3.236098 6.294922-6.294922 1.470588-5.588235 1.470703-17.175781 1.470703-17.175781s.058709-11.64614-1.470703-17.2343752c-.823529-3.0588236-3.236098-5.4713925-6.294922-6.2949219C58.058249-.00011488 35.705078 0 35.705078 0zm-7.117187 14.294922L47.175781 25l-18.58789 10.705078V14.294922z"
                        transform="scale(1.7)"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="horiz-ln" />
          <div className="footer-bottom">
            <meta itemProp="copyrightYear" content="2024" />
            <meta itemProp="copyrightHolder" content="Филиал EXEED Cars Rus" />
            <p>© 2024 Филиал EXEED Cars Rus</p>
            <a
              aria-label="Правовая информация"
              href="https://exeed.ru/terms-conditions/"
            >
              Правовая информация
            </a>
          </div>
        </div>
      </footer>
    </>
  ) : (
    <></>
  );
}
