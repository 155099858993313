import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Result.style.sass";
import ResultModel from "./ResultModel.jsx";
import { useSearchParams } from "react-router-dom";
import { APIgetCityDealer, APIpostFromData } from "../../../../api/api.js";

export default function Result({ outputAnswers, modelParam, data }) {
  const result = data.result.result;
  const askStructure = data.result.askStructure;
  const modelsId = data.models;
  const form = data.result.form;

  const model = makeModelArray(data.result.result);

  function makeModelArray(data) {
    let output = [];
    data.forEach((e) => {
      output.push(e.model);
    });
    return output;
  }

  const numOfModel = isAfterQuiz();

  function calculateWeights(askStructure, models, asks) {
    const result = [];
    let maxInd = 0;
    models.forEach((model) => {
      result.push({
        modelNum: model.modelNum,
        weightSum: 0,
      });
    });

    asks.forEach((ask) => {
      const askItem = askStructure.find((e) => e.askNum === ask.ask);

      if (askItem) {
        ask.answers.forEach((answerNum) => {
          const answerItem = askItem.answers.find(
            (e) => e.answerNum === answerNum
          );

          if (answerItem) {
            answerItem.weights.forEach((weight) => {
              const modelItem = result.find(
                (e) => e.modelNum === weight.modelNum
              );

              if (modelItem) {
                modelItem.weightSum += weight.weight;
              }
            });
          }
        });
      }
    });

    for (let i in result) {
      if (result[i].weightSum > result[maxInd].weightSum) {
        maxInd = i;
      }
    }
    return result[maxInd].modelNum;
  }

  const [, setSearchParams] = useSearchParams({});

  const handleChangeParams = (e) => {
    setSearchParams({ model: e });
  };

  function isAfterQuiz() {
    if (outputAnswers && outputAnswers.length > 0) {
      return calculateWeights(askStructure, model, outputAnswers);
    } else {
      return model.findIndex((e) => {
        return e.modelName === modelParam;
      });
    }
  }

  useLayoutEffect(() => {
    handleChangeParams(model[numOfModel].modelName);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [city, setCity] = useState([]);
  const [dealer, setDealer] = useState([]);

  async function getDealers() {
    await APIgetCityDealer()
      .then((response) => {
        setCity(response.data);
      })
      .catch(function (error) {
        setCity(data.dealerCenters);
        throw new Error(error);
      });
  }

  async function postForm(formData) {
    const outputData = {
      data: {
        dealer_center: formData.dealer_center,
        date: formData.date,
        first_name: formData.name,
        last_name: formData.surname,
        phone: "+" + formData.phone.replace(/[^\d]/g, ""),
        email: formData.email,
        comment: formData.comment,
        communication: formData.communication,
        vehicle_model: {
          id: modelsId.find(
            (e) => e.name === result[numOfModel].model.modelName
          ).id,
          collection_id: "vehicles_models",
        },
        source: "exeed_ru",
      },
      collectionID: "forms_quiz_test_drive",
      spaceID: "cb6kstaeibkpl3dtsceg",
      envID: "cb6kstaeibkpl3dtscf0",
    };
    await APIpostFromData(data.result.form.pathToSend, outputData)
      .then((response) => {
        console.log(response.status);
      })
      .catch(function (error) {
        throw new Error(error);
      });
  }

  useEffect(() => {
    getDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSend, setIsSend] = useState(false);
  const [isSuccesSend, setIsSuccesSend] = useState(false);
  const [isInProcess, setIsInProcess] = useState(false);

  return data ? (
    <div className="result">
      <ResultModel
        city={city}
        dealer={dealer}
        setDealer={setDealer}
        result={result}
        numOfModel={numOfModel}
        postForm={postForm}
        isSend={isSend}
        setIsSend={setIsSend}
        isSuccesSend={isSuccesSend}
        setIsSuccesSend={setIsSuccesSend}
        isInProcess={isInProcess}
        setIsInProcess={setIsInProcess}
        form={form}
        buttons={data.result.buttons}
      />
    </div>
  ) : (
    <></>
  );
}
