import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.style.sass";
import QuizResult from "./components/pages/QuizResult/QuizResult";
import FullPage from "./components/pages/FullPage/FullPage";
import axios from "axios";

function App() {
  const [, setUpdate] = useState(Date.now());
  const [data, setData] = useState();

  async function getData() {
    const list = await axios.get("data/data.json");
    const info = list.data;
    setData(info);
  }

  useEffect(() => {
    getData();
  }, []);

  return data ? (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/quiz-frame"
            element={
              <QuizResult data={data} setUpdate={setUpdate} isFrame={true} />
            }
          />
          <Route
            path="/quiz"
            element={<FullPage data={data} page="quiz" setUpdate={setUpdate} />}
          />
          <Route
            path="/"
            element={
              <FullPage data={data} page="welcome" setUpdate={setUpdate} />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  ) : (
    <></>
  );
}

export default App;
