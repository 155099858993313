import React from "react";
import "./WelcomePage.style.sass";
import Arrow from "../../UI/Arrow/Arrow";
import ButtonModel from "../../UI/ButtonModel/ButtonModel";

export default function WelcomePage({data}) {

  return  (
    <div className="welcome-page">
      <div className="image">
        <picture>
          <source
            srcSet={data.welcome.cover.mobile.avif}
            media={"(max-width: 767px) or (orientation: portrait)"}
            type="image/avif"
          />
          <source
            srcSet={data.welcome.cover.mobile.webp}
            media={"(max-width: 767px) or (orientation: portrait)"}
            type="image/webp"
          />
          <source
            srcSet={data.welcome.cover.mobile.jpg}
            media={"(max-width: 767px) or (orientation: portrait)"}
          />
          <source srcSet={data.welcome.cover.desktop.avif} type="image/avif" />
          <source srcSet={data.welcome.cover.desktop.webp} type="image/webp" />
          <source srcSet={data.welcome.cover.desktop.jpg} />
          <img
            src={data.welcome.cover.desktop.jpg}
            alt="Изображение автомобильного салона"
          />
        </picture>
      </div>
      <div className="container">
        <div className="text">
          <h1>
          {data.welcome.title}
          </h1>
          <h2>
            {data.welcome.text}
          </h2>
          <div>
            <ButtonModel linkTo="/quiz">
              <p>{data.welcome.button}</p>
              <Arrow direction={"right"} />
            </ButtonModel>
          </div>
        </div>
      </div>
    </div>
  );
}
