import React, { useState } from "react";
import "./Form.style.sass";
import Arrow from "../Arrow/Arrow";
import ButtonModel from "../ButtonModel/ButtonModel";
import moment from "moment";

export default function Form({
  city,
  dealer,
  setDealer,
  setIsSend,
  postForm,
  isInProcess,
  setIsInProcess,
  setIsSuccesSend,
}) {
  const [formData, setFormData] = useState({
    dealer_center: {
      id: "",
      collection_id: "dealers_dealerships",
    },
    date: moment().format("YYYY-MM-DD"),
    name: "",
    surname: "",
    phone: "",
    email: "",
    comment: "",
    communication: false,
    city_id: "",
  });

  const [isNameValid, setIsNameValid] = useState(true);
  const [isNameAdded, setIsNameAdded] = useState(true);
  const [isSurnameValid, setIsSurnameValid] = useState(true);
  const [isMailValid, setIsMailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isDealerValid, setIsDealerValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isTipForDealer, setIsTipForDealer] = useState(false);
  const nameValid = new RegExp("^[A-zА-яЁё]+$");

  function onClickSubmit(e) {
    e.preventDefault();

    let flag = false;
    const mailValid = new RegExp(
      "([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9_-]+)"
    );
    const phoneValid = new RegExp(
      "[+7 (]+[0-9]{3}[) ]+[0-9]{3}[-]+[0-9]{2}[-]+[0-9]{2}"
    );

    if (formData.email.length > 0) {
      if (!mailValid.test(formData.email)) {
        setIsMailValid(false);
        flag = true;
      }
    }

    if (!isSurnameValid || !isNameValid) {
      flag = true;
    }

    if (formData.name.length <= 0) {
      setIsNameAdded(false);
      flag = true;
    }

    if (formData.phone.length <= 0 || !phoneValid.test(formData.phone)) {
      setIsPhoneValid(false);
      flag = true;
    }

    if (formData.city_id === "0" || formData.city_id.length <= 0) {
      setIsCityValid(false);
      flag = true;
    }

    if (formData.dealer_center.id.length <= 0) {
      setIsDealerValid(false);
      flag = true;
    }

    if (flag === false) {
      setIsInProcess(true);
      postForm(formData)
        .then(function (response) {
          setIsSend(true);
          setIsSuccesSend(true);
          setIsInProcess(false);
        })
        .catch(function (error) {
          setIsSend(true);
          setIsSuccesSend(false);
          setIsInProcess(false);
        });
    }
  }

  function phoneEnter(e) {
    let prevphone = formData.phone.replace(/[^\d]/g, "");
    let curPhone = e.replace(/[^\d]/g, "");
    if (prevphone.length >= curPhone.length && prevphone.length < 11) {
      setFormData({ ...formData, phone: e });
      return;
    }
    let outputPhone = "";
    let newPhone = "";
    if (curPhone !== "") {
      for (let i = 0; i < curPhone.length; i++) {
        newPhone = outputPhone + curPhone[i];
        switch (newPhone.length) {
          // 7||8 -> +7  // 9 -> +7 (9
          case 1: {
            if (newPhone === "7" || newPhone === "8") {
              outputPhone = "+7";
            } else {
              outputPhone = "+7 (" + newPhone;
            }
            break;
          }
          // +7# -> +7 (#
          case 3: {
            outputPhone = "+7 (" + newPhone[2];
            break;
          }

          // +7 (9# -> +7 (9#
          case 6: {
            outputPhone = newPhone;
            break;
          }

          // +7 (99# -> +7 (99#)
          case 7: {
            outputPhone = newPhone + ")";
            break;
          }

          // +7 (999)# -> +7 (999) #
          case 9: {
            outputPhone = newPhone.slice(0, -1) + " " + newPhone[8];
            break;
          }

          // +7 (999) 9# -> +7 (999) 9#
          case 11: {
            outputPhone = newPhone;
            break;
          }

          // +7 (999) 99# -> +7 (999) 99#-
          case 12: {
            outputPhone = newPhone + "-";
            break;
          }

          // +7 (999) 999-# -> +7 (999) 999-#
          case 14: {
            outputPhone = newPhone;
            break;
          }

          // +7 (999) 999-9# -> +7 (999) 999-9#-
          case 15: {
            outputPhone = newPhone + "-";
            break;
          }

          case 17: {
            outputPhone = newPhone;
            break;
          }

          case 18: {
            outputPhone = newPhone;
            break;
          }

          default:
            break;
        }
      }
      setFormData({ ...formData, phone: outputPhone });
    } else {
      setFormData({ ...formData, phone: "" });
    }
  }

  return (
    <form autoComplete="on">
      <div key={"name"} className="form-module">
        <label
          className={isNameAdded ? (isNameValid ? "" : "invalid") : "invalid"}
          htmlFor="name"
        >
          Ваше имя*
        </label>
        <input
          disabled={isInProcess}
          className={isNameAdded ? (isNameValid ? "" : "invalid") : "invalid"}
          value={formData.name}
          onChange={(e) => {
            if (nameValid.test(e.target.value) || e.target.value.length === 0) {
              setFormData({ ...formData, name: e.target.value });
              setIsNameValid(true);
            } else {
              setIsNameValid(false);
              setTimeout(() => {
                setIsNameValid(true);
              }, 3000);
            }
            setIsNameAdded(true);
          }}
          type="text"
          id="name"
          name="name"
          placeholder="Укажите имя"
          required
        />
        <p className={isNameAdded ? "" : "invalid"}>*Это обязательное поле</p>
        <p className={isNameValid ? "" : "invalid"}>
          *Введите имя одним словом
        </p>
      </div>
      <div key={"surname"} className="form-module">
        <label className={isSurnameValid ? "" : "invalid"} htmlFor="surname">
          Ваша фамилия
        </label>
        <input
          disabled={isInProcess}
          className={isSurnameValid ? "" : "invalid"}
          value={formData.surname}
          onChange={(e) => {
            if (nameValid.test(e.target.value) || e.target.value.length === 0) {
              setFormData({ ...formData, surname: e.target.value });
              setIsSurnameValid(true);
            } else {
              setIsSurnameValid(false);
              setTimeout(() => {
                setIsSurnameValid(true);
              }, 3000);
            }
          }}
          type="text"
          id="surname"
          name="surname"
          placeholder="Укажите фамилию"
          required
        />
        <p className={isSurnameValid ? "" : "invalid"}>
          *Введите фамилию одним словом
        </p>
      </div>
      <div key={"phone"} className="form-module">
        <label className={isPhoneValid ? "" : "invalid"} htmlFor="phone">
          Ваш телефон*
        </label>
        <input
          disabled={isInProcess}
          className={isPhoneValid ? "" : "invalid"}
          value={formData.phone}
          onChange={(e) => {
            phoneEnter(e.target.value);
            setIsPhoneValid(true);
          }}
          type="tel"
          id="phone"
          name="phone"
          placeholder="+7 (000) 000-00-00"
          pattern="\+7 \(\d\d\d\) \d\d\d-\d\d-\d\d"
          required
        />
        <p className={isPhoneValid ? "" : "invalid"}>*Это обязательное поле</p>
      </div>
      <div key={"email"} className="form-module">
        <label className={isMailValid ? "" : "invalid"} htmlFor="email">
          Ваш e-mail
        </label>
        <input
          disabled={isInProcess}
          className={isMailValid ? "" : "invalid"}
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value.toLowerCase() });
            setIsMailValid(true);
          }}
          placeholder="example@example.ru"
          type="email"
          id="email"
          name="email"
        />
        <p className={isMailValid ? "" : "invalid"}>
          *Введите корректный формат электронной почты
        </p>
      </div>
      <div key={"city"} className="form-module">
        <label className={isCityValid ? "" : "invalid"} htmlFor="city">
          Выберете город*
        </label>
        <select
          disabled={isInProcess}
          required
          className={isCityValid ? (isTipForDealer ? "tip" : "") : "invalid"}
          name="city"
          id="city"
          onChange={(e) => {
            setIsCityValid(true);
            setIsTipForDealer(false);
            if (e.target.value === "0") {
              setDealer([]);
              setFormData({
                ...formData,
                city_id: e.target.value,
                dealer_center: { collection_id: "dealers_dealerships", id: "" },
              });
            } else {
              let dealerArray = city.find((element) => {
                return element.id === e.target.value;
              }).dealerCenters;
              setFormData({
                ...formData,
                city_id: e.target.value,
                dealer_center: {
                  collection_id: "dealers_dealerships",
                  id: dealerArray[0].id,
                },
              });
              setIsDealerValid(true);
              setDealer(dealerArray);
            }
          }}
        >
          <option disabled={true} value={0} selected={true}>
            Город
          </option>
          {city.length > 0 ? (
            <>
              {city.map((e, index) => {
                return (
                  <option key={index} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </>
          ) : (
            <option disabled={true}>Загрузка...</option>
          )}
        </select>
        <p className={isCityValid ? "" : "invalid"}>*Это обязательное поле</p>
      </div>
      <div key={"dealer"} className="form-module">
        <label
          className={
            isDealerValid ? (isTipForDealer ? "invalid" : "") : "invalid"
          }
          htmlFor="dealer"
        >
          Выберете дилерский центр*
        </label>
        <select
          disabled={
            isInProcess ||
            formData.city_id === "0" ||
            formData.city_id.length <= 0
          }
          required
          className={
            isDealerValid ? (isTipForDealer ? "invalid" : "") : "invalid"
          }
          name="dealer"
          id="dealer"
          onClick={() => {
            setIsDealerValid(true);
          }}
          onChange={(e) => {
            setFormData({
              ...formData,
              dealer_center: {
                collection_id: "dealers_dealerships",
                id: e.target.value,
              },
            });
          }}
        >
          {dealer.length > 0 ? (
            <>
              {dealer.map((e, index) => {
                return (
                  <option key={index} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </>
          ) : (
            <option disabled={true} selected={true}>
              {isTipForDealer ? "Сначала выберете город" : "Дилерский центр"}
            </option>
          )}
        </select>
        <div
          onClick={() => {
            if (formData.city_id === "0" || formData.city_id.length <= 0) {
              setIsTipForDealer(true);
              setTimeout(() => {
                setIsTipForDealer(false);
              }, 3000);
            }
          }}
          className={
            formData.city_id === "0" || formData.city_id.length <= 0
              ? "error-handler"
              : "error-handler none"
          }
        ></div>
        <p className={isDealerValid ? "" : "invalid"}>*Это обязательное поле</p>
      </div>
      <div className="form-checker">
        <div
          id="checker"
          className={formData.communication ? "active" : ""}
          onClick={() => {
            setFormData({
              ...formData,
              communication: !formData.communication,
            });
          }}
        ></div>
        <p>
          Даю согласие на{" "}
          <a href="https://exeed.ru/advertising_agreement/">
            {" "}
            дальнейшую коммуникацию
          </a>
        </p>
      </div>
      <div className="form-submit">
        <div>
          {isInProcess ? (
            <ButtonModel id="submit" type="submit">
              <p>Отправка...</p>
              <Arrow direction={"right"} />
            </ButtonModel>
          ) : (
            <ButtonModel
              id="submit"
              type="submit"
              onClick={(e) => {
                onClickSubmit(e);
              }}
            >
              <p>Отправить заявку</p>
              <Arrow direction={"right"} />
            </ButtonModel>
          )}
        </div>
      </div>
      <div className="form-info">
        <p
          className={isNameValid ? (isPhoneValid ? "" : "invalid") : "invalid"}
        >
          *Все поля, отмеченные звездочкой, обязательны для заполнения.{" "}
        </p>
        <p>
          Отправляя данную форму, вы даёте своё{" "}
          <a href={"https://exeed.ru/processing_agreement/"}>
            cогласие на обработку персональных данных
          </a>
          .
        </p>
      </div>
    </form>
  );
}
