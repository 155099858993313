import React from "react";
import "./NavBottom.style.sass";
import Arrow from "../Arrow/Arrow";

export default function NavBottom({
  isEnd,
  isStart,
  goToPrev,
  goToNext,
  isAnswered,
}) {
  return (
    <div
      className="nav-btns"
      style={isStart ? { flexDirection: "row-reverse" } : {}}
    >
      {!isStart ? (
        <div onClick={goToPrev} className="nav-btn">
          <Arrow direction={"left"} />
          <p>Назад</p>
        </div>
      ) : (
        <></>
      )}

      {isAnswered ? (
        isEnd ? (
          <div onClick={goToNext} className="nav-btn">
            <p style={{ color: "white", fontSize: "16px" }}>
              Перейти к результату
            </p>
            <Arrow direction={"right"} />
          </div>
        ) : (
          <div onClick={goToNext} className="nav-btn">
            <p>Вперед</p>
            <Arrow direction={"right"} />
          </div>
        )
      ) : (
        <p>Выберите ответ</p>
      )}
    </div>
  );
}
